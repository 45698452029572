import React from 'react';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/water-damage-dos-and-donts';

import config from '../config';

const WaterDamageDosAndDontsPage = () => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Water Damage Do's And Dont's</h1>
      <h2>Water Damage Do's</h2>
      <ul>
        <li>
          CALL SCI-TECH’S RESTORATION EXPERTS at{' '}
          <a href={config.phoneNumberHref}>{config.phoneNumber}</a> – If no
          visual mold is present & if water is a category 1 (from a clean water
          supply line) you can use fans to circulate the air and assist drying
          until help arrives.
        </li>
        <li>Remove as much water as possible by mopping and blotting.</li>
        <li>Wipe furniture dry.</li>
        <li>
          Lift draperies off carpet, loop through a coat hanger, and place the
          hanger on the drapery rod.
        </li>
        <li>
          Prop up wet furniture cushions for even drying and place small wood
          blocks or aluminum foil under furniture legs.
        </li>
        <li>Remove wet area rugs or other floor coverings.</li>
        <li>
          If affected, open furniture drawers, closet doors, and luggage to
          enhance drying.
        </li>
        <li>
          Move photos, paintings, and art objects to a safe, dry location.
        </li>
        <li>
          Remove wet fabrics and dry them as soon as possible. Hang furs and
          leather goods to dry separately at room temperature.
        </li>
        <li>Remove damp books from shelves and spread out to dry.</li>
        <li>
          If damage occurs during a cool season, leave heat on; if in summer,
          use an air conditioner if available.
        </li>
      </ul>
      <p>
        *Damage from the water and bacteria growth can begin within just hours
        of the intrusion.
      </p>
      <h2>Water Damage Don’ts</h2>
      <ul>
        <li>
          Do NOT call your insurance company without consulting with a
          professional water damage restoration company first!
        </li>
        <li>Do not use an ordinary household vacuum to remove water.</li>
        <li>
          Do not use electrical appliances while on wet carpet or wet floors.
        </li>
        <li>
          Do not go into rooms with standing water if the electricity is still
          on.
        </li>
        <li>
          Do not lift tacked down carpet without professional help. Lifting the
          carpet incorrectly could promote shrinkage and delamination.
        </li>
      </ul>
      <p>
        Do not wait to call Sci-Tech’s Restoration Experts at{' '}
        <a href={config.phoneNumberHref}>{config.phoneNumber}</a> for
        professional help.
      </p>
    </Wrapper>
  </Layout>
);

export default WaterDamageDosAndDontsPage;
